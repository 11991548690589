import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import DecisionButtons from '../../../components/DecisionButtons';

import styles from '../styles/trip.module.scss';

const LABELS = {
  CONFIR: 'Вы действительно хотите изменить последнюю версию?',
  DANGEROUS_CONFIRM: 'Изменение последней версии заказа невозможено. Она учтена в закрывающих документах:',
  YES: 'Да',
  EDIT: 'Изменить',
};

class AdjustDialog extends Component {
  static propTypes = {
    // eslint-disable-next-line
    activeDialog: PropTypes.string.isRequired,
    closingDocuments: PropTypes.array.isRequired,
    disableActive: PropTypes.func.isRequired,
    confirmAdjust: PropTypes.func.isRequired,
  };

  state = {
    confirmAdjustActualVersionDialog: false,
    dangerousConfirmAdjustActualVersionDialog: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const resetState = (active) => {
      const resetedState = {};

      // eslint-disable-next-line
      for (let key in this.state) {
        resetedState[key] = false;
      }

      resetedState[active] = true;

      this.setState(resetedState);
    };

    if (nextProps.activeDialog === '') {
      resetState();
    } else if (!(nextProps.activeDialog in this.state)) {
      // ничего не делаем
    } else {
      resetState(nextProps.activeDialog);
    }
  }

  closeDialog = () => {
    this.setState({ dangerousConfirmAdjustActualVersionDialog: false });

    this.props.disableActive();
  }

  renderDangerousConfirmDialog= () => {
    const { closingDocuments } = this.props;

    const mappingDocuments = closingDocuments.map((item, idx) => <div key={ idx }>{ item }</div>);

    return (
      <Dialog onClick={ () => this.closeDialog() }>
        <div className={ styles['user-dialog'] }>
          <div className={ styles['closing-list'] }>
            <div>{ LABELS.DANGEROUS_CONFIRM }</div><br />
            { mappingDocuments }
          </div>
          <div className={ `${styles.row} ${styles.right}` }>
            <DecisionButtons
              onCancel={ () => this.closeDialog() }
              onSave={ () => this.handlerConfirm('dangerousConfirmAdjustActualVersionDialog') }
              labelSave={ LABELS.EDIT }
            />
          </div>
        </div>
      </Dialog>
    );
  }

  handlerConfirm = (field) => {
    this.closeDialog(field);
    this.props.confirmAdjust();
  }

  renderConfirmDialog = () => (
    <Dialog onClick={ () => this.closeDialog('confirmAdjustActualVersionDialog') }>
      <div className={ styles['user-dialog'] }>
        <div>{LABELS.CONFIR}</div>
        <div className={ `${styles.row} ${styles.right}` }>
          <DecisionButtons
            onCancel={ () => this.closeDialog('confirmAdjustActualVersionDialog') }
            onSave={ () => this.handlerConfirm('confirmAdjustActualVersionDialog') }
            labelSave={ LABELS.YES }
          />
        </div>
      </div>
    </Dialog>
  );

  render() {
    const {
      confirmAdjustActualVersionDialog,
      dangerousConfirmAdjustActualVersionDialog,
    } = this.state;

    const confirmDialogHtml = confirmAdjustActualVersionDialog && this.renderConfirmDialog();
    const dangerousConfirmDialogHtml = dangerousConfirmAdjustActualVersionDialog && this.renderDangerousConfirmDialog();

    return (
      <div>
        {confirmDialogHtml}
        {dangerousConfirmDialogHtml}
      </div>
    );
  }
}

export { AdjustDialog };
