import renderComponents from './components';

const NAME = 'sw-settings';

class Settings {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const appService = context.getService('App');
    const companiesService = context.getService('Companies');
    const tripService = context.getService('Trip');
    const businessService = context.getService('Business');
    const featureFlagsService = context.getService('FeatureFlags');
    const hotelWarningService = context.getService('hotelWarning');
    const taxiWarningService = context.getService('taxiWarning');
    const transferWarningService = context.getService('transferWarning');
    const vipHallWarningService = context.getService('vipHallWarning');

    this.page = renderComponents(this.box, {
      appService,
      companiesService,
      tripService,
      businessService,
      featureFlagsService,
      hotelWarningService,
      taxiWarningService,
      transferWarningService,
      vipHallWarningService,
    });
  }

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, Settings);
}
